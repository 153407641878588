.chartCss {
    max-height: 500px;
}

@keyframes width-pulse {
    0% {
      stroke-width: 6px
    }
    50% {
      stroke-width: 14px;
    }
    100% {
      stroke-width: 6px;
    }
  }
  @keyframes dashoffset-seven {
    0% {
      stroke-dashoffset: 7px;
    }
    100% {
      stroke-dashoffset: 0px;
    }
  }
  @keyframes dasharray-craziness {
    0% {
      stroke-dasharray: 7px 2px;
    }
    80% {
      stroke-dasharray: 7px 100px;
      stroke-width: 10px
    }
    100% {
      stroke-dasharray: 7px 2px;
    }
  }
  /* .ct-chart .ct-label.ct-vertical,
  .ct-chart .ct-label.ct-horizontal {
    color: rgba(255, 255, 255, 0.5);
  } */
  .ct-chart .ct-grid.ct-vertical,
  .ct-chart .ct-grid.ct-horizontal {
    /* stroke: rgba(255, 255, 255, 0.1); */
    stroke-dasharray: 2px;
    shape-rendering: crispEdges;
  }
  
  /* .ct-chart .ct-series.ct-series-a .ct-line {
    stroke: #4ECDC4;
    stroke-width: 2px;
    stroke-linecap: round;
    animation: width-pulse 2s infinite;
    
  } */
  
  /* .ct-series-a .ct-point { */
    /* Colour of your points */
    /* stroke: rgb(0, 255, 115); */
    /* Size of your points */
    /* stroke-width: 7px; */
    /* Make your points appear as squares */
    /* stroke-linecap: round; */
  /* } */

  .ct-chart .ct-series.ct-series-a .ct-line {
    stroke: #4ECDC4;
    stroke-width: 5px;
    stroke-linecap: round;
    stroke-dasharray: 7px 7px;
    animation: dashoffset-seven 150ms infinite linear;
  }
  .ct-series-a .ct-point {
    /* Colour of your points */
    /* stroke: #24ebaf; */
    stroke: rgb(0, 255, 115);
    /* Size of your points */
    stroke-width: 2px;
    /* Make your points appear as squares */
    stroke-linecap: round;
  }

  .ct-chart .ct-series.ct-series-b .ct-line {
    stroke: #C7F464;
    stroke-width: 5px;
    stroke-dasharray: 5px 2px;
    animation: dashoffset-seven 200ms infinite linear;
  }
  .ct-chart .ct-series.ct-series-c .ct-line {
    stroke: #FF6B6B;
    stroke-width: 3px;
    stroke-linecap: round;
    stroke-dasharray: 30px 5px;
    animation: dasharray-craziness 10s infinite linear;
  }